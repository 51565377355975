<template>
	<div class="dashboard-car-info">
		<div class="car-info-content" :style="{ top: `${positionY}px`, left: `${positionX}px` }">
			<div class="title-content">
				<p class="ve-num">车辆：{{ carInfo.vehicleNo }}</p>
				<span class="close-icon" @click="showCarInfo = false"></span>
			</div>
			<div class="main-content">
				<div class="left-content">
					<p class="position">
						<span>当前位置：{{ address }}</span>
					</p>
					<p class="position">
						<span class="ll">经度：{{ carInfo.lng }}</span>
						<span class="ll">纬度：{{ carInfo.lat }}</span>
					</p>
					<div class="car-img">
						<p class="car-status">
							<span>车辆状态</span>
							<span class="status-value">{{ carStatus[carData.ovInfo?.vs] || "--" }}</span>
						</p>
					</div>
					<div class="left-bottom">
						<p class="soc">
							<span class="soc-text">电量</span>
							<span class="soc-text1">{{ carData.ovInfo?.soc || "--" }}</span>
							<span class="soc-text2">%</span>
						</p>
						<div class="progress">
							<div class="pro-item" v-for="i in progressNum" :key="i"></div>
							<!-- <p class="pro-area" :style="{ width: `${carData.ovInfo?.soc || 0}%` }"></p> -->
						</div>
						<div class="mill-total mill-zhuan">
							<p class="text">总里程</p>
							<p class="value">
								<span class="value-1">{{ carData.ovInfo?.mil || "--" }}</span>
								<span class="value-2">km</span>
							</p>
						</div>
						<div class="zhuan-su mill-zhuan">
							<p class="text">电机转速</p>
							<p class="value">
								<span class="value-1">{{ carData?.emotor[0]?.scrspd || "--" }}</span>
								<span class="value-2">r/min</span>
							</p>
						</div>
					</div>
				</div>
				<div class="center-line"></div>
				<div class="right-content">
					<div class="spd">
						<p class="value">{{ carData.ovInfo?.cspd || "--" }}</p>
						<p class="unit">km/h</p>
						<p class="text">车速</p>
					</div>
					<div class="stop">
						<div class="left">
							<p class="oli">油门</p>
							<p class="stop-text">刹车</p>
						</div>
						<div class="middle"></div>
						<div class="right">
							<div class="top">
								<div class="top-area" :style="{ width: `${carData.ovInfo?.accelv || 0}%` }"></div>
							</div>
							<div class="bottom">
								<div class="bottom-area" :style="{ width: `${carData.ovInfo?.breaks || 0}%` }"></div>
							</div>
						</div>
					</div>
					<div class="elect">
						<div class="elect-item">
							<div class="top">
								<span class="icon"></span>
								<span class="text">电机电压</span>
							</div>
							<div class="bottom">
								<span class="value">{{ carData?.emotor[0]?.civol || "--" }}</span>
								<span class="unit">V</span>
							</div>
						</div>
						<div class="elect-item">
							<div class="top">
								<span class="icon icon2"></span>
								<span class="text">电机电流</span>
							</div>
							<div class="bottom">
								<span class="value">{{ carData?.emotor[0]?.ccur || "--" }}</span>
								<span class="unit">A</span>
							</div>
						</div>
						<div class="elect-item">
							<div class="top">
								<span class="icon"></span>
								<span class="text">电池电压</span>
							</div>
							<div class="bottom">
								<span class="value">{{ carData.hfuel?.vol || "--" }}</span>
								<span class="unit">V</span>
							</div>
						</div>
						<div class="elect-item">
							<div class="top">
								<span class="icon icon2"></span>
								<span class="text">电池电流</span>
							</div>
							<div class="bottom">
								<span class="value">{{ carData.hfuel?.cur || "--" }}</span>
								<span class="unit">A</span>
							</div>
						</div>
					</div>
					<div class="wendu">
						<div class="wendu-item">
							<p class="value">{{ carData?.emotor[0]?.temp || "--" }}°C</p>
							<p class="text">电机温度</p>
						</div>
						<div class="wendu-item">
							<p class="value">{{ carData?.emotor[0]?.ctemp || "--" }}°C</p>
							<p class="text">控制器温度</p>
						</div>
						<div class="wendu-item">
							<p class="value green">{{ carData.limit?.mintempv || "--" }}°C</p>
							<p class="text">单体最低温度</p>
						</div>
						<div class="wendu-item">
							<p class="value green">{{ carData.limit?.mtempv || "--" }}°C</p>
							<p class="text">单体最高温度</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCompleteAddressByPoint, getLngLatToPosition } from "@/common/js/BMapUtil";
import mixinsData from "@/common/js/carMonitor.mixin.js";

export default {
	data() {
		return {
			address: "",
			carData: {
				emotor: []
			},
			timer: null,
			progressNum: 0,
			ws: null,
			positionY: 20,
			positionX: 20
		};
	},
	mixins: [mixinsData],
	props: {
		value: Boolean,
		map: Object,
		carInfo: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		showCarInfo: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			}
		}
	},
	created() {
		this.init();
		this.setInter();
	},
	destroyed() {
		this.clearInter();
	},
	methods: {
		init() {
			const { lng, lat, vehicleId } = this.carInfo;
			// 计算坐标位置在屏幕位置
			let { x, y } = getLngLatToPosition(this.map, [lng, lat]);
			this.positionX = x - 900 || 0;
			this.positionY = y - 200 || 0;
			getCompleteAddressByPoint(lng, lat).then(rs => {
				this.address = rs.address;
			});
			const url = "/crrc-palm-bus/vehicleMonitor/queryGb32960History";
			this.$http.post(url, { vehicleId }, { baseType: "base" }).then(res => {
				this.carData = res || {};
				this.getProgressList();
			});
		},
		getProgressList() {
			const value = this.carData.ovInfo?.soc || 0;
			const unit = 100 / 20;
			const num = Math.round(value / unit);
			this.progressNum = num;
		},
		setInter() {
			this.clearInter();
			this.timer = setInterval(() => {
				this.init();
			}, 5000);
		},
		clearInter() {
			this.timer && clearInterval(this.timer);
			this.timer = null;
		}
		/* initWs() {
			const url = "";
			this.ws = new WebSocket(url);
			const self = this;
			this.ws.onopen = function () {
				if (self.ws.readyState == 1) {
					console.log("连接成功");
					const params = {};
					self.ws.send(JSON.stringify(params));
				}
			};
			this.ws.onmessage = function (res) {
				console.log(res);
			};
		},
		closeWs() {
			this.ws && this.ws.close();
			this.ws = null;
		} */
	}
};
</script>

<style lang="scss" scoped>
.dashboard-car-info {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: top;
	justify-content: left;
	z-index: 99999;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.3);
	.car-info-content {
		position: absolute;
		top: 0;
		left: 0;
		transform: scale(0.7);
		width: 1022px;
		height: 585px;
		background: url("~@/assets/bigScreen/dashboard-car/content_bg.png") no-repeat;
		background-size: 100% 100%;
		padding: 10px;
		.title-content {
			position: relative;
			height: 59px;
			background: url("~@/assets/bigScreen/dashboard-car/title_bg.png") no-repeat;
			background-size: 100% 100%;
			.ve-num {
				font-size: 25px;
				font-weight: 600;
				color: #fff;
				line-height: 29px;
				padding: 18px 0 0 63px;
			}
			.close-icon {
				position: absolute;
				right: 11px;
				top: 11px;
				width: 14px;
				height: 14px;
				background: url("~@/assets/bigScreen/dashboard-car/close_icon.png") no-repeat;
				background-size: 100% 100%;
				cursor: pointer;
			}
		}
		.main-content {
			display: flex;
			align-items: flex-start;
			.left-content {
				padding: 0 25px 0 30px;
				.position {
					padding: 11px 0 0 29px;
					font-size: 16px;
					color: #ffffff;
					line-height: 17px;
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					&:first-child {
						margin-bottom: 0;
					}
					.ll {
						font-size: 14px;
						margin-right: 16px;
					}
				}
				.car-img {
					width: 511px;
					height: 271px;
					background: url("~@/assets/bigScreen/dashboard-car/car_icon.png") no-repeat;
					background-size: 100% 100%;
					margin-left: 105px;
					.car-status {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 18px;
						color: #ffffff;
						line-height: 20px;
						.status-value {
							padding: 4px 13px;
							background: url("~@/assets/bigScreen/dashboard-car/status_bg.png") no-repeat;
							background-size: 100% 100%;
							margin-left: 7px;
						}
					}
				}
				.left-bottom {
					position: relative;
					width: 670px;
					height: 160px;
					background: url("~@/assets/bigScreen/dashboard-car/soc_bg.png") no-repeat;
					background-size: 100% 100%;
					// margin-top: -16px;
					.soc {
						display: flex;
						align-items: center;
						padding-top: 66px;
						margin-left: 282px;
						.soc-text {
							font-size: 16px;
							font-weight: 600;
							color: #ffffff;
							line-height: 17px;
							letter-spacing: 4px;
							margin-right: 3px;
						}
						.soc-text1 {
							font-size: 28px;
							font-weight: 600;
							color: #9cfe08;
							line-height: 33px;
							letter-spacing: 8px;
						}
						.soc-text2 {
							font-size: 16px;
							color: #fff;
							line-height: 33px;
						}
					}
					.progress {
						position: absolute;
						left: 266px;
						bottom: 40px;
						width: 140px;
						height: 14px;
						padding-top: 1px;
						display: flex;
						align-items: center;
						.pro-item {
							height: 12px;
							width: 6px;
							border-radius: 1px;
							margin-right: 1px;
							background: linear-gradient(270deg, #00caff 0%, #0095ff 100%);
						}
					}
					.mill-zhuan {
						position: absolute;
						text-align: center;
						&.mill-total {
							left: 41px;
							bottom: 20px;
						}
						&.zhuan-su {
							right: 30px;
							bottom: 20px;
						}
						.text {
							font-size: 14px;
							color: #ffffff;
							line-height: 14px;
							margin-bottom: 2px;
						}
						.value {
							.value-1 {
								font-size: 22px;
								font-weight: 600;
								color: #ffffff;
								line-height: 25px;
								letter-spacing: 6px;
							}
							.value-2 {
								font-size: 14px;
								color: #ffffff;
								letter-spacing: 3px;
							}
						}
					}
				}
			}
			.center-line {
				width: 1px;
				height: 498px;
				border: 1px solid;
				border-image: linear-gradient(180deg, rgba(200, 200, 200, 0.1), rgba(21, 91, 114, 1), rgba(151, 151, 151, 0.1)) 1 1;
			}
			.right-content {
				width: 221px;
				margin-left: 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.spd {
					width: 158px;
					height: 173px;
					background: url("~@/assets/bigScreen/dashboard-car/spd_bg.png") no-repeat;
					background-size: 100% 100%;
					text-align: center;
					padding-top: 67px;
					.value {
						font-size: 28px;
						font-weight: 600;
						color: #ffffff;
						line-height: 33px;
					}
					.unit {
						font-size: 18px;
						color: #ffffff;
						line-height: 20px;
					}
					.text {
						font-size: 16px;
						font-weight: 600;
						color: #ffffff;
						line-height: 16px;
						margin-top: 10px;
					}
				}
				.stop {
					display: flex;
					align-items: center;
					padding: 5px 14px 0 6px;
					margin-bottom: 22px;
					.left {
						padding-top: 8px;
						.oli,
						.stop-text {
							font-size: 14px;
							color: #d0deee;
							line-height: 14px;
						}
						.oli {
							margin-bottom: 14px;
						}
					}
					.middle {
						width: 1px;
						height: 54px;
						background: #6c8097;
						margin-left: 7px;
					}
					.right {
						padding-top: 7px;
						.top,
						.bottom {
							width: 160px;
							height: 15px;
							background: rgba(108, 128, 151, 0.1);
							// opacity: 0.1;
							padding-top: 5px;
							.top-area {
								height: 5px;
								background: linear-gradient(90deg, rgba(156, 255, 8, 0) 0%, #00fff1 100%);
							}
							.bottom-area {
								height: 5px;
								background: linear-gradient(90deg, rgba(255, 87, 56, 0) 0%, #ff5738 100%);
							}
						}
						.top {
							margin-bottom: 10px;
						}
					}
				}
				.elect {
					border: 1px solid;
					border-image: linear-gradient(180deg, rgba(200, 200, 200, 0.1), rgba(21, 91, 114, 1), rgba(151, 151, 151, 0.1)) 1 1;
					border-left: none;
					border-right: none;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					padding: 7px 0;
					.elect-item {
						width: 50%;
						padding: 7px 0 7px 6px;
						.top {
							display: flex;
							align-items: center;
							.icon {
								width: 14px;
								height: 14px;
								background: url("~@/assets/bigScreen/dashboard-car/elect_1.png") no-repeat;
								background-size: 100% 100%;
								margin-right: 4px;
								&.icon2 {
									background: url("~@/assets/bigScreen/dashboard-car/elect_2.png") no-repeat;
									background-size: 100% 100%;
								}
							}
							.text {
								font-size: 14px;
								color: #ffffff;
								line-height: 14px;
							}
						}
						.bottom {
							padding-left: 17px;
							margin-top: 6px;
							.value {
								font-size: 16px;
								font-weight: 600;
								color: #00caff;
								line-height: 17px;
							}
							.unit {
								font-size: 12px;
								color: #d3d3d3;
								margin-left: 4px;
							}
						}
					}
				}
				.wendu {
					display: flex;
					margin-top: 20px;
					padding-left: 4px;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					.wendu-item {
						margin-bottom: 9px;
						width: 99px;
						height: 42px;
						text-align: center;
						padding-top: 5px;
						background: url("~@/assets/bigScreen/dashboard-car/t_bg.png") no-repeat;
						background-size: 100% 100%;
						.value {
							font-size: 16px;
							color: #ffffff;
							line-height: 17px;
							&.green {
								color: #11e3bf;
							}
							&.orange {
								color: #fd5738;
							}
						}
						.text {
							font-size: 14px;
							color: #ffffff;
							line-height: 14px;
						}
					}
				}
			}
		}
	}
}
</style>
