<template>
	<div class="car-eng-info">
		<CardTemplate>
			<template #title>车辆能耗监控</template>
			<div class="data-list">
				<div class="table-list">
					<div class="t-head">
						<span class="per-1">序号</span>
						<span class="per-2">车牌</span>
						<span class="per-3">当日充电量</span>
						<span class="per-4">当日耗电量</span>
						<span class="per-5">百公里能耗</span>
					</div>
					<div class="data-for">
						<vue-seamless-scroll v-if="dataList.length" :data="dataList" class="warp" :class-option="classOption">
							<div class="t-body">
								<div
									class="data-item"
									v-for="(item, index) in dataList"
									:key="index"
									:class="index % 2 !== 0 ? 'border-item' : ''"
								>
									<div class="per-1">
										<!-- eslint-disable-next-line -->
										{{ index < 9 ? "0" + (index + 1) : index + 1 }}
									</div>
									<div class="per-2" :class="index < 3 ? 'no-padding' : ''">
										<!-- <span v-if="index < 3" class="td-cirle" :class="'td-' + index"></span> -->
										<span>{{ item.vehicleNo }}</span>
									</div>
									<div class="per-3">
										{{ item.chargeCapacity + "kw/h" }}
									</div>
									<div class="per-4">
										{{ item.powerConsumption + "kw/h" }}
									</div>
									<div class="per-5">
										{{ item.pcPerMil + "kw/h" }}
									</div>
								</div>
							</div>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import { mapState } from "vuex";
import { alarmTypeListMock } from "../../mock";

export default {
	components: {
		CardTemplate,
		vueSeamlessScroll
	},
	data() {
		return {
			dataList: [],
			classOption: {
				singleHeight: 28,
				limitMoveNum: 5
			},
			timer: null
		};
	},
	computed: {
		...mapState(["isMockData"])
	},
	watch: {
		isMockData() {
			this.init();
		}
	},
	created() {
		this.init();
	},
	activated() {
		this.setInter();
	},
	deactivated() {
		this.clearInter();
	},
	methods: {
		async init() {
			this.dataList = [];
			if (this.isMockData) {
				this.dataList = alarmTypeListMock || [];
				return;
			}
			const url = `/crrc-palm-bus/gis/summary/energyConsumptionInfo`;
			const params = {};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.dataList = res || [];
				this.setInter();
			});
		},
		setInter() {
			this.clearInter();
			this.timer = setInterval(
				() => {
					this.init();
				},
				2 * 60 * 1000
			);
		},
		clearInter() {
			this.timer && clearInterval(this.timer);
			this.timer = null;
		}
	}
};
</script>

<style lang="scss">
.car-eng-info {
	.data-list {
		padding: 15px;
		width: 100%;

		.table-list {
			width: 100%;
			font-size: 14px;
			color: #fff;
			.t-head {
				display: flex;
				align-items: center;
				line-height: 28px;
			}
			.per-1 {
				width: 10%;
				text-align: center;
			}
			.per-2 {
				width: 25%;
				padding-left: 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				/* &.no-padding {
					padding-left: 0;
				} */
			}
			.per-3 {
				width: 22%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.per-4 {
				width: 22%;
			}
			.per-4 {
				width: 21%;
			}
			.data-for {
				height: 112px;
			}
			.warp {
				width: 100%;
				height: 112px;
				overflow: hidden;
			}
			.data-item {
				display: flex;
				height: 28px;
				align-items: center;
				&.border-item {
					border: 1px solid rgba(108, 128, 151, 0.2);
				}
				.td-cirle {
					display: inline-block;
					height: 6px;
					width: 6px;
					border-radius: 50%;
					margin-right: 4px;
				}
				.td-0 {
					background: #f66303;
				}
				.td-1 {
					background: #828280;
				}
				.td-2 {
					background: #f7b500;
				}
				.td-bg {
					display: inline-block;
					width: 58px;
					height: 20px;
					line-height: 20px;
					font-weight: 600;
				}
				.tdbg-0 {
					background: rgba(78, 212, 161, 0.2);
					color: #4ccf9e;
				}
				.tdbg-1 {
					background: rgba(0, 160, 233, 0.2);
					color: #00a0e9;
				}
				.tdbg-2 {
					background: rgba(191, 191, 191, 0.2);
					color: #888888;
				}
				.tdbg-3 {
					background: rgba(224, 66, 54, 0.2);
					color: #e04236;
				}
				.tdbg-4 {
					background: rgba(0, 160, 233, 0.2);
					color: #00a0e9;
				}
			}
		}
	}
}
</style>
