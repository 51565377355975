<template>
	<div class="car-info">
		<CardTemplate>
			<template #title>车辆信息</template>
			<div class="data-content">
				<div>
					<div class="car-info-echarts" id="carInfoEcharts"></div>
					<p class="cav-des">车辆总数</p>
				</div>
				<div class="right-info">
					<div class="top-info">
						<div class="left">
							<p class="text">车辆总数</p>
							<p>
								<span class="num">{{ carInfo.vehicleSum || 0 }}</span>
								<span class="unit">台</span>
							</p>
						</div>
						<div class="center"></div>
						<div class="right">
							<p class="text on-line">
								<span>在线：</span>
								<span class="num">{{ carInfo.onlineSum || 0 }}</span>
								<span class="unit">台</span>
							</p>
							<p class="text out-line">
								<span>离线：</span>
								<span class="num">{{ carInfo.offlineSum || 0 }}</span>
								<span class="unit">台</span>
							</p>
						</div>
					</div>
					<div class="bottom-info">
						<p class="top">
							<span class="w-half">
								<span class="text">纯电动</span>
								<span class="num">{{ carInfo.electricSum || 0 }}</span>
							</span>
							<span class="w-half">
								<span class="text">天然气</span>
								<span class="num">{{ carInfo.naturalGasSum || 0 }}</span>
							</span>
						</p>
						<p class="middle"></p>
						<p class="bottom">
							<span class="w-half">
								<span class="text">汽油</span>
								<span class="num">{{ carInfo.gasolineSum || 0 }}</span>
							</span>
							<span class="w-half">
								<span class="text">混合动力</span>
								<span class="num">{{ carInfo.hybridSum || 0 }}</span>
							</span>
						</p>
					</div>
				</div>
			</div>
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
import { mapState } from "vuex";
import { carInfoMock } from "../../mock";
import Highcharts from "highcharts/highstock";
// import * as HighchartsMore from "highcharts/highcharts-more";
// import * as HighchartsDrilldown from "highcharts/modules/drilldown";
import Highcharts3D from "highcharts/highcharts-3d";

// HighchartsMore(Highcharts);
// HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);

export default {
	components: {
		CardTemplate
	},
	data() {
		return {
			highchartsOptions: {
				chart: {
					type: "pie",
					backgroundColor: "transparent",
					margin: [0, 0, 0, 0],
					options3d: {
						enabled: true,
						alpha: 50
					}
				},
				credits: {
					enabled: false
				},
				legend: {
					enabled: false
				},
				tooltip: {
					enabled: true,
					borderColor: "transparent",
					backgroundColor: "rgba(0,0,0,0.7)",
					style: {
						color: "#fff"
					},
					headerFormat: "",
					pointFormat: "{point.name}: {point.y}"
					/* formatter(params, a) {
						console.log(params, a);
						return `<p style={color: '#fff';}>123123</p>`;
						// return params.options.pointFormat;
					} */
				},
				title: {
					text: "",
					verticalAlign: "middle",
					y: 30,
					style: {
						color: "#fff",
						fontSize: 18
					}
				},
				subtitle: {
					text: ""
				},
				plotOptions: {
					pie: {
						innerSize: "80%",
						depth: 25,
						dataLabels: {
							enabled: false
						},
						// slicedOffset: 20,
						point: {
							// 每个扇区是数据点对象，所以事件应该写在 point 下面
							events: {
								// 鼠标滑过是，突出当前扇区
								mouseOver() {
									this.slice();
								},
								// 鼠标移出时，收回突出显示
								mouseOut() {
									this.slice();
								},
								// 默认是点击突出，这里屏蔽掉
								click() {
									return false;
								}
							}
						}
					}
				},
				series: [
					{
						name: "数量",
						size: "150%",
						data: []
					}
				]
			},
			/* myEchartsOptions: {
				title: {
					text: "",
					x: "center",
					y: "center",
					textStyle: {
						rich: {
							num: {
								fontWeight: "bold",
								color: "#fff",
								fontSize: 18
							}
						}
					}
				},
				tooltip: {
					show: true,
					position(point) {
						return [point[0] + 20, point[1] - 40];
					}
				},
				legend: {
					show: false
				},
				series: [
					{
						name: "",
						type: "pie",
						radius: ["70%", "90%"],
						avoidLabelOverlap: false,
						emphasis: {
							scale: true,
							scaleSize: 3
						},
						label: { show: false },
						labelLine: {
							show: false
						},
						data: []
					}
				]
			}, */
			carInfo: {
				electricSum: 0,
				gasolineSum: 0,
				hybridSum: 0,
				naturalGasSum: 0,
				offlineSum: 0,
				onlineSum: 0,
				vehicleSum: 0
			}
		};
	},
	computed: {
		...mapState(["isMockData"])
	},
	watch: {
		isMockData() {
			this.init();
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			if (this.isMockData) {
				this.carInfo = carInfoMock;
				this.formatData();
				return;
			}
			const url = `/crrc-palm-bus/gis/summary/vehicleInfo`;
			const params = {};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.carInfo = res || {};
				this.formatData();
			});
		},
		async formatData() {
			await this.$nextTick();
			const { electricSum, gasolineSum, hybridSum, naturalGasSum } = this.carInfo;
			// const titleText = [`{num|${this.carInfo.vehicleSum}}`];
			const seriesData = [
				{
					name: "纯电动",
					y: electricSum || null,
					color: "rgba(0, 255, 241, 0.9)"
				},
				{
					name: "汽油",
					y: gasolineSum || null,
					color: "rgba(77, 166, 255, 0.8)"
				},
				{
					name: "混合动力",
					y: hybridSum || null,
					color: "rgba(255, 217, 130, 0.7)"
				},
				{
					name: "天然气",
					y: naturalGasSum || null,
					color: "rgba(90, 243, 184, 0.7)"
				}
				/* { value: electricSum, name: "纯电动", itemStyle: { color: "rgba(90, 243, 184, 0.7)" } },
				{ value: gasolineSum, name: "汽油", itemStyle: { color: "#4D74FF" } },
				{ value: hybridSum, name: "混合动力", itemStyle: { color: "#FFD982" } },
				{ value: naturalGasSum, name: "天然气", itemStyle: { color: "#5AF3B8" } } */
			];
			// this.myEchartsOptions.title.text = titleText;
			// this.myEchartsOptions.series[0].data = seriesData;
			this.highchartsOptions.title.text = this.carInfo.vehicleSum;
			this.highchartsOptions.series[0].data = seriesData;
			// const myEcharts = this.$echarts.init(document.getElementById("carInfoEcharts"));
			// myEcharts.setOption(this.myEchartsOptions);
			Highcharts.chart("carInfoEcharts", this.highchartsOptions);
		}
	}
};
</script>

<style lang="scss" scoped>
.car-info {
	.data-content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 20px;
		width: 100%;

		.car-info-echarts {
			position: relative;
			width: 150px;
			height: 100px;
			z-index: 99;
		}

		.cav-des {
			font-size: 12px;
			text-align: center;
			color: #fff;
			line-height: 27px;
		}

		.right-info {
			flex: 1;
			margin-left: 30px;

			.top-info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 14px;

				.left {
					.text {
						font-size: 14px;
						color: #fff;
					}

					.num {
						font-size: 21px;
						color: #00fff1;
						font-weight: bold;
					}

					.unit {
						font-size: 12px;
						color: #00fff1;
					}
				}

				.center {
					// width: 1px;
					height: 42px;
					border: 1px solid;
					border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(2, 75, 144, 1)) 1 1;
				}

				.right {
					.text {
						font-size: 14px;
						color: #fff;
						line-height: 27px;

						&.on-line {
							.num {
								font-weight: bold;
								color: #03fff1;
							}

							.unit {
								color: #03fff1;
							}
						}

						&.out-line {
							.num {
								font-weight: bold;
								color: #ffda0a;
							}

							.unit {
								color: #ffda0a;
							}
						}
					}
				}
			}

			.bottom-info {
				font-size: 14px;
				color: rgba(255, 255, 255, 0.85);

				.top,
				.bottom {
					display: flex;
					justify-content: space-between;
					align-items: center;
					// line-height: 30px;
					.w-half {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: calc((100% - 20px) / 2);
					}
					.text {
						line-height: 30px;
					}
					.num {
						font-family: "BMDohyeon";
						// font-weight: blod;
					}
				}

				.middle {
					width: 100%;
					// height: 1px;
					border: 1px solid;
					border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(2, 75, 144, 1)) 1 1;
				}
			}
		}
	}
}
</style>
