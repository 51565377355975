<template>
	<div class="map-wrap">
		<div class="top_bg"></div>
		<div class="flex title_content">
			<div class="flex title">
				<img src="@/assets/bigScreen/icon_jnjp.png" alt="" />
				<span class="first-span">
					<span class="fz-16">当月累计节能减排</span>
					<span class="data">
						<span class="num-value" id="a0">{{ energyEmissionSum }}</span>
						<span>千克</span>
					</span>
				</span>
				<span>
					<span class="fz-16">相当于植树</span>
					<span class="data">
						<span class="num-value" id="a1">{{ plantTreeSum }}</span>
						<span>颗</span>
					</span>
				</span>
			</div>
		</div>
		<div id="bMap" class="map-box"></div>
		<div class="left-content">
			<BaseInfo></BaseInfo>
			<CarInfo></CarInfo>
			<UserActiveStatistics></UserActiveStatistics>
			<AlarmType></AlarmType>
		</div>
		<div class="right-content">
			<OperateView></OperateView>
			<MileageStatistic></MileageStatistic>
			<WorkInfo></WorkInfo>
			<PassegeFlowStatistics></PassegeFlowStatistics>
		</div>
		<DashboardCarInfo v-if="showCarInfo" v-model="showCarInfo" :carInfo="carInfo" :map="BMap"></DashboardCarInfo>
		<!-- <right-aside ref="rAside"></right-aside> -->
	</div>
</template>
<script>
import coordtransform from "coordtransform";
import bMapStyleJson from "@/assets/json/bMapStyle";
import BaseInfo from "../components/BaseInfo/index";
import CarInfo from "../components/CarInfo/index";
import UserActiveStatistics from "../components/UserActiveStatistics/index";
import AlarmType from "../components/AlarmType/index";
import OperateView from "../components/OperateView/index";
import MileageStatistic from "../components/MileageStatistic/index";
import WorkInfo from "../components/WorkInfo/index";
import PassegeFlowStatistics from "../components/PassegeFlowStatistics/index";
import CustomPoint from "@/utils/customePoint";
import DashboardCarInfo from "../components/DashboardCarInfo/index";
import { wsHeader } from "@/config/wsconfig";

export default {
	name: "",
	components: {
		BaseInfo,
		CarInfo,
		UserActiveStatistics,
		AlarmType,
		OperateView,
		MileageStatistic,
		WorkInfo,
		PassegeFlowStatistics,
		DashboardCarInfo
	},
	data() {
		return {
			BMap: null,
			energyEmissionSum: null,
			plantTreeSum: null,
			emissionTimer: null,
			num: 1,
			clusterer: null,
			carInfo: {},
			showCarInfo: false,
			ws: null,
			wsTime: 0
		};
	},
	mounted() {},
	async activated() {
		await this.initMap();
		this.getEmissionInfo();
		// 定时查询
		this.setInter();
	},
	deactivated() {
		this.clearInter();
		this.ws?.close();
	},
	methods: {
		async initMap() {
			const baiduMap = new BMapGL.Map("bMap", { minZoom: 10, maxZoom: 18 });
			baiduMap.centerAndZoom(new window.BMapGL.Point(89.192671, 42.95001), 18); // 初始化地图,设置中心点坐标和地图级别
			baiduMap.setMapStyleV2({ styleJson: bMapStyleJson.bMapJson });
			baiduMap.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
			baiduMap.setHeading(15);
			baiduMap.setTilt(50);
			this.BMap = baiduMap;
			await this.getLine(baiduMap);
			await this.getCarInfo();
		},
		// 获取所有线路信息，并绘制在地图上
		async getLine(map) {
			const url = "/crrc-palm-bus/gis/line/lineMap";
			const res = await this.$http.post(url, {}, { baseType: "base" });
			const allStation = [];
			res.map(item => {
				const { siteVoList } = item;
				const drivingItem = new BMapGL.DrivingRoute(map);
				const siteList = siteVoList.map(site => {
					const point = coordtransform.wgs84togcj02(site.longitude, site.latitude);
					const p = coordtransform.gcj02tobd09(point[0], point[1]);
					const stationPoint = new BMapGL.Point(p[0], p[1]);
					allStation.push(stationPoint);
					return stationPoint;
				});
				for (let i = 0; i < siteList.length; i++) {
					if (i !== siteList.length - 1) {
						drivingItem?.search(siteList[i], siteList[i + 1]); // 绘制两个点之间的路线
					}
				}
				drivingItem?.setSearchCompleteCallback(() => {
					const pts = drivingItem?.getResults().getPlan(0).getRoute(0).getPath();
					const polyline = new BMapGL.Polyline(pts, {
						strokeColor: "#048CFF",
						strokeWeight: 5,
						strokeOpacity: 1
					});
					map?.addOverlay(polyline);
				});
				map?.setViewport(allStation);
			});
		},
		// 获取车辆实时位置
		async getCarInfo() {
			const url = "/crrc-palm-bus/gis/line/realtime";
			const res = await this.$http.post(url, {}, { baseType: "base" });
			const vinList = res.map(item => {
				this.addBusPos(item);
				return item.vin;
			});
			if (!this.ws) {
				const ws = new WebSocket(`${wsHeader}//${location.host}/ischedule/vehicleMonitor/gb32960`);
				ws.onopen = () => {
					console.log("打开了ws链接，等待消息");
					ws.send(JSON.stringify(vinList));
				};
				ws.onmessage = event => {
					console.log("接受消息推送", event.data);
					try {
						const carInfo = JSON.parse(event.data);
						const { data, info } = carInfo;
						const { vin } = data;
						const index = vinList.indexOf(vin);
						const car = res[index];
						if (car) {
							this.addBusPos({ ...car, ...data, ...info });
						}
					} catch (error) {
						console.log("非格式化数据");
					}
				};
				this.wsTime = setInterval(() => {
					// 心跳包
					ws.send(JSON.stringify(vinList));
				}, 10000);
				this.ws = ws;
			}
		},
		getEmissionInfo() {
			const url = "/crrc-palm-bus/gis/summary/emissionInfo";
			this.$http.post(url, {}, { baseType: "base" }).then(res => {
				this.energyEmissionSum = res.energyEmissionSum;
				this.plantTreeSum = res.plantTreeSum;
				const value = this.num * 360;
				const dom0 = document.getElementById("a0");
				const dom1 = document.getElementById("a1");
				dom0.style.transform = `rotateX(${value}deg)`;
				dom1.style.transform = `rotateX(${value}deg)`;
				this.num++;
			});
		},
		// 添加车辆位置标注
		addBusPos(posInfo) {
			const map = this.BMap;
			const pcoord = gcoord.transform([posInfo.lng, posInfo.lat], gcoord.WGS84, gcoord.BD09);
			const bdPoint = new BMapGL.Point(pcoord[0], pcoord[1]);
			const marker = new CustomPoint(bdPoint, { txt: posInfo.vehicleNo, lineName: posInfo.routeName });
			marker.id = posInfo.vehicleId;
			const markers = map.getOverlays();
			marker.id = posInfo.vehicleId;
			for (let i = 0; i < markers.length; i++) {
				if (markers[i].id === posInfo.vehicleId) {
					map?.removeOverlay(markers[i]);
				}
			}
			map?.addOverlay(marker);
			marker.addEventListener("click", () => {
				const { lng, lat } = posInfo;
				const p = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
				this.carInfo = {
					...posInfo,
					lng: p[0],
					lat: p[1]
				};
				this.showCarInfo = true;
			});
			return marker;
		},
		setInter() {
			this.emissionTimer && this.clearInter();
			this.emissionTimer = setInterval(() => {
				this.getEmissionInfo();
			}, 5000);
		},
		clearInter() {
			clearInterval(this.emissionTimer);
			clearInterval(this.wsTime);
			this.emissionTimer = null;
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep {
	.ra-wrapper {
		z-index: 9999;
	}
	.station_mark {
		color: #ffffff;
		&:hover {
			.car_number {
				visibility: visible;
			}
		}
		.car_number {
			width: 136px;
			background: url("~@/assets/bigScreen/car_number.png") no-repeat 0 0 / 100% 100% transparent;
			font-size: 16px;
			padding: 15px;
			font-weight: 600;
			visibility: hidden;
			text-align: center;
		}
		&.active {
			.car_icon {
				transform: scale(1.3);
			}
		}
		.car_icon {
			width: 62px;
			height: 82px;
			background: url("~@/assets/bigScreen/car_back.png") no-repeat 0 0 /100% 100% transparent;
		}
	}
}
.map-wrap,
#bMap {
	position: relative;
	// z-index: 1;
	width: 100%;
	height: 100%;
}

.map-wrap {
	.top_bg {
		position: absolute;
		top: 0;
		z-index: 6;
		width: 100%;
		height: 165px;
		background: url("~@/assets/bigScreen/top_title_bg.png") no-repeat 0 0 transparent;
		background-size: 100% 100%;
	}
	.title_content {
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 10px;
		width: 100%;
		z-index: 99;
	}
	.title {
		align-items: center;
		padding: 0 106px 8px 102px;
		min-height: 58px;
		color: #fff;
		background: url("~@/assets/bigScreen/top_bk.png") no-repeat 0 100% transparent;
		background-size: 100%;
		box-sizing: border-box;

		& > img {
			margin-right: 11px;
			width: 89px;
			height: 84px;
		}

		.fz-16 {
			font-size: 16px;
		}

		.first-span {
			margin-right: 30px;
		}

		.data {
			font-family: "BMDohyeon";
			margin-left: 21px;
			font-size: 32px;
			color: #00fff1;

			& > span:last-child {
				margin-left: 12px;
				font-family: "Avenir", Helvetica, Arial, sans-serif;
				font-size: 14px;
			}
		}
	}

	.left-content {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1000;
		width: 428px;
	}

	.right-content {
		position: absolute;
		top: 0;
		right: 16px;
		z-index: 1000;
		width: 428px;
	}
	.video-wrap {
		font-size: 12px;
		padding: 0 20px;
		li {
			padding: 5px 0;
			cursor: pointer;
		}
	}
	.num-value {
		display: inline-block;
		transition: all 0.5s;
	}
}
</style>
