<template>
	<div class="mileage-statis">
		<CardTemplate>
			<template #title>里程统计</template>
			<template #operate>
				<el-select class="select" size="mini" v-model="time" @change="init">
					<el-option v-for="(item, index) in timeOption" :key="index" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</template>
			<div class="data-content">
				<div id="myEcharts" class="my-echarts"></div>
			</div>
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
import dayjs from "dayjs";
import { mapState } from "vuex";
import { mileageStatisticMock } from "../../mock";

export default {
	components: {
		CardTemplate
	},
	data() {
		return {
			time: "month",
			timeOption: [
				{ label: "本年", value: "year" },
				{ label: "本月", value: "month" }
			],
			maxValue: 0,
			dataList: [],
			echartOptions: {
				grid: {
					right: 10,
					top: 10,
					bottom: 30,
					left: 30
				},
				tooltip: {
					show: true,
					trigger: "axis",
					backgroundColor: "transparent",
					borderWidth: 0,
					padding: 0,
					formatter(params) {
						const obj = params[0];
						const { name, value } = obj;
						return `<div style="background: linear-gradient(180deg, rgba(67,67,67,0.7) 0%, rgba(0,0,0,0.8) 100%);
box-shadow: 0px 0px 10px 0px rgba(96,150,255,0.7);padding: 10px;border-radius: 4px"><p style="color: #fff;">${name}</p><p style="color: #fff;">${value}公里</p></div>`;
					},
					axisPointer: {
						lineStyle: {
							type: "solid",
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									{
										offset: 0,
										color: "rgba(126,199,255,0)" // 0% 处的颜色
									},
									{
										offset: 0.5,
										color: "rgba(126,199,255,1)" // 100% 处的颜色
									},
									{
										offset: 1,
										color: "rgba(126,199,255,0)" // 100% 处的颜色
									}
								],
								global: false // 缺省为 false
							}
						}
					}
				},
				xAxis: {
					type: "category",
					data: [],
					boundaryGap: false,
					axisLine: {
						show: false,
						lineStyle: {
							color: "rgba(255,255,255,0.65)"
						}
					}
				},
				yAxis: {
					type: "value",
					splitLine: {
						lineStyle: {
							color: "rgba(255,255,255,0.15)"
						}
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: "rgba(255,255,255,0.65)"
						}
					}
				},
				series: [
					{
						data: [],
						type: "line",
						smooth: true,
						itemStyle: {
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [
									{
										offset: 0,
										color: "#3DE6FF"
									},
									{
										offset: 1,
										color: "#1968FF"
									}
								]
							}
						},
						areaStyle: {
							//区域填充样式
							normal: {
								//线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
								color: {
									type: "linear",
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: "rgba(54,161,255,0.6)"
										},
										{
											offset: 1,
											color: "rgba(25,104,255,0)"
										}
									]
								},
								// shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
								shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
							}
						}
					}
				]
			}
		};
	},
	computed: {
		...mapState(["isMockData"]),
		isYear() {
			return this.time === "year";
		},
		chartLeft() {
			return Math.max(30, (String(this.maxValue).length + 1) * 10 - 5);
		}
	},
	watch: {
		isMockData() {
			this.init();
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			if (this.isMockData) {
				this.dataList = mileageStatisticMock || [];
				this.formatData();
				return;
			}
			const url = `/crrc-palm-bus/gis/summary/mileageInfo`;
			const params = {
				month: this.isYear ? "" : dayjs().format("YYYY-MM"),
				year: this.isYear ? dayjs().format("YYYY") : ""
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.dataList = res || [];
				this.formatData();
			});
		},
		async formatData() {
			await this.$nextTick();
			this.echartOptions.xAxis.data = this.dataList.map(item => {
				let { month, day } = item;
				let [, monthValue] = month ? month.split("-") : [];
				let [, , dayValue] = day ? day.split("-") : [];
				const value = this.isYear ? `${Number(monthValue)}月` : `${Number(dayValue)}日`;
				return value;
			});
			this.echartOptions.series[0].data = this.dataList.map(item => item.total);
			if (this.echartOptions.series[0].data.length) {
				this.maxValue = Math.ceil(Math.max.apply(null, this.echartOptions.series[0].data));
			}
			this.echartOptions.grid.left = this.chartLeft;
			const myEcharts = this.$echarts.init(document.getElementById("myEcharts"));
			myEcharts.setOption(this.echartOptions);
		}
	}
};
</script>

<style lang="scss">
.mileage-statis {
	.select {
		width: 78px;
		height: 25px;

		.el-input__inner {
			padding: 10px;
			color: #d9e7ff;
			background-color: transparent;
			border: 1px solid #a0a9b8;

			&::placeholder {
				color: #d9e7ff;
			}
		}

		.el-input .el-select__caret {
			color: #d9e7ff;
		}
	}

	.data-content {
		padding: 20px;

		.my-echarts {
			z-index: 99;
			height: 130px;
		}
	}
}
</style>
