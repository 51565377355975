<template>
	<div class="base-info">
		<CardTemplate>
			<template #title>营运总览</template>
			<div class="data-list">
				<div class="data-item">
					<img src="@/assets/bigScreen/yyzl_icon_01.png" alt="" />
					<div class="des">
						<p class="title">运营总里程</p>
						<p class="value">
							<span class="number">{{ operationalInfo.mileageTotal || 0 }}</span>
							<span>公里</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="~@/assets/bigScreen/yyzl_icon_02.png" alt="" />
					<div class="des">
						<p class="title">总客流</p>
						<p class="value">
							<span class="number">{{ operationalInfo.passengerTotal || 0 }}</span>
							<span>人次</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/yyzl_icon_03.png" alt="" />
					<div class="des">
						<p class="title">总班次</p>
						<p class="value">
							<span class="number">{{ operationalInfo.shiftsTotal || 0 }}</span>
							<span>趟</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/yyzl_icon_04.png" alt="" />
					<div class="des">
						<p class="title">总收入</p>
						<p class="value">
							<span class="number">{{ operationalInfo.revenueSum || 0 }}</span>
							<span>元</span>
						</p>
					</div>
				</div>
			</div>
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
import { mapState } from "vuex";
import { operationalInfoMock } from "../../mock";

export default {
	components: {
		CardTemplate
	},
	data() {
		return {
			operationalInfo: {
				mileageTotal: 0,
				passengerTotal: 0,
				revenueSum: 0,
				shiftsTotal: 0
			},
			timer: null
		};
	},
	computed: {
		...mapState(["isMockData"])
	},
	watch: {
		isMockData() {
			this.init();
		}
	},
	/* created() {
		this.init();
		this.setInter();
	}, */
	activated() {
		this.init();
		this.setInter();
	},
	deactivated() {
		this.clearInter();
	},
	methods: {
		async init() {
			if (this.isMockData) {
				this.operationalInfo = operationalInfoMock || [];
				return;
			}
			const url = `/crrc-palm-bus/gis/summary/operationalInfo`;
			const params = {};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.operationalInfo = res || {};
			});
		},
		setInter() {
			this.timer && this.clearInter();
			this.timer = setInterval(() => {
				this.init();
			}, 5000);
		},
		clearInter() {
			clearInterval(this.timer);
			this.timer = null;
		}
	}
};
</script>

<style lang="scss" scoped>
.base-info {
	.data-list {
		display: flex;
		justify-content: space-around;
		padding-bottom: 16px;
		width: 100%;
		flex-wrap: wrap;

		.data-item {
			display: flex;
			align-items: center;
			padding-left: 20px;
			// justify-content: center;
			margin-top: 16px;
			width: 50%;

			img {
				width: 65px;
				height: 64px;
			}

			.des {
				margin-left: 16px;

				.title {
					font-size: 16px;
					color: #fff;
				}

				.value {
					font-size: 14px;
					color: #00fff1;

					.number {
						font-size: 18px;
						font-weight: 600;
					}
				}
			}
		}
	}
}
</style>
