<template>
	<div class="base-info">
		<CardTemplate>
			<template #title>基础信息</template>
			<div class="data-list">
				<div class="data-item">
					<img src="@/assets/bigScreen/jcxx_icon_01.png" alt="" />
					<div class="des">
						<p class="title">线路条数</p>
						<p class="value">
							<span class="number">{{ baseInfo.lineSum }}</span>
							<span>条</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/jcxx_icon_02.png" alt="" />
					<div class="des">
						<p class="title">站点数量</p>
						<p class="value">
							<span class="number">{{ baseInfo.stationSum }}</span>
							<span>个</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/jcxx_icon_03.png" alt="" />
					<div class="des">
						<p class="title">线网长度</p>
						<p class="value">
							<span class="number">{{ baseInfo.lineNetSum }}</span>
							<span>km</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/jcxx_icon_04.png" alt="" />
					<div class="des">
						<p class="title">车辆总数</p>
						<p class="value">
							<span class="number">{{ baseInfo.vehicleSum }}</span>
							<span>台</span>
						</p>
					</div>
				</div>
			</div>
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
import { mapState } from "vuex";
import { baseInfoMock } from "../../mock";

export default {
	data() {
		return {
			baseInfo: {
				lineNetSum: 0,
				lineSum: 0,
				stationSum: 0,
				vehicleSum: 0
			}
		};
	},
	components: {
		CardTemplate
	},
	computed: {
		...mapState(["isMockData"])
	},
	watch: {
		isMockData() {
			this.init();
		}
	},
	created() {
		this.init();
	},
	methods: {
		async init() {
			if (this.isMockData) {
				this.baseInfo = baseInfoMock;
				return;
			}
			const url = `/crrc-palm-bus/gis/summary/baseInfo`;
			const params = {};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.baseInfo = res || {};
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.base-info {
	.data-list {
		display: flex;
		justify-content: space-around;
		padding-bottom: 16px;
		width: 100%;
		flex-wrap: wrap;

		.data-item {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 16px;
			width: 50%;

			img {
				width: 65px;
				height: 64px;
			}

			.des {
				margin-left: 16px;

				.title {
					font-size: 16px;
					color: #fff;
				}

				.value {
					font-size: 14px;
					color: #00fff1;

					.number {
						font-size: 24px;
						font-weight: 600;
					}
				}
			}
		}
	}
}
</style>
